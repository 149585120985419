import styled from "styled-components";
const CartTableStyled = styled.table `

  border-collapse: collapse;
  text-align: center;
  button,input {
    border: none;
    outline: none;
    font-weight: 500;
  }
  td{
    border: 1px solid #d6d6d6;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;

  }
  td button {
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: var(--app-semi-black);
  }
  td input {
    width: 100%;    
    height: 100%;
    text-align: center;
    color: var(--app-black);
  }
`
export default CartTableStyled;