import React from 'react'
import { BiPencil, BiSearch } from 'react-icons/bi'
import CustomInput from '../CustomInput/CustomInput'
import SearchStyled from './SearchStyled'

function Search() {
  return (
    <SearchStyled>
      <div>
        <CustomInput />
      </div>
      <div>
        <button>
          <BiSearch color='#fff' size={25} />
        </button>
      </div>
      <div>
        <button>
          <BiPencil color='#fff' size={25} />
        </button>
      </div>      
    </SearchStyled>
  )
}

export default Search