import React from 'react'
import { BiCrosshair, BiSearch, BiX } from 'react-icons/bi'
import { FaCross, FaCrosshairs } from 'react-icons/fa'
import SearchComponentStyled from './SearchComponentStyled'

function SearchComponent({onHide}) {
  return (
    <SearchComponentStyled>
      <BiSearch size={25} />
      <input type="text" autoFocus />
      <BiX onClick={onHide} size={25} />
    </SearchComponentStyled>
  )
}

export default SearchComponent