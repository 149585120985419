import React from 'react'
import MarineStoryOverviewStyled from './MarineStoryOverviewStyled'

function MarineStoryOverview() {
  return (
    <MarineStoryOverviewStyled>
      <h2>Marine Innovation</h2>
      <p>
        '바다'에서 환경문제 해결의 혁신을 찾다.
      </p>
      <button>
        마린이노베이션 홈페이지 가기
      </button>
    </MarineStoryOverviewStyled>
  )
}

export default MarineStoryOverview