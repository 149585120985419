import React, { useState } from 'react'
import FilterButtons from '../FilterButtons/FilterButtons'
import ProductScrollList from '../ProductScrollList/ProductScrollList';
import ProductList from '../ProductList/ProductList'
import ProductsSectionStyled from './ProductsSectionStyled'

function ProductsSection({title: strTitle, products: all, buttons: myButtons, scroll: isScroll}) {
  const [filtered, setFiltered] = useState(all);
  const [buttons, setButtons] = useState(myButtons);
  const [title, setTitle] = useState(strTitle);

  const onClick = (index) => {
    if (buttons[index].active == false) {
      setButtons(
        buttons.map((button) => {
          button.active = false;
          return button;
        })
      );
      var clicked = buttons[index];
      clicked.active = true;
    }
    if(buttons[index].title === "All") {
      setFiltered(all);
    }else{
      setFiltered(all.filter(product=>product.category === buttons[index].title))
    }
  }
  isScroll = isScroll === undefined ? false : isScroll;
  
  return (
    <ProductsSectionStyled>
      <h3>{strTitle}</h3>
      {/* 필터 */}
      {/* <FilterButtons buttons={buttons} onClick={(index)=>onClick(index)}/> */}
      {isScroll === false ? (
        <ProductList products={all} />
      ): 
      (
        <ProductScrollList products={all} />
      )}
    </ProductsSectionStyled>
  )
}

export default ProductsSection