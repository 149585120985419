import styled from "styled-components";
const NotYetBuiltStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 10px;

  img {
    width: 60%;
  }

  button {
    padding: 10px 20px;
    background-color: var(--app-blue);
    color: #fff;
    border: 1px var(--app-border-color);
    border-radius: 5px;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    h3 {
      font-size: 20px;
    }

    p {
      text-align: center;
      font-size: 18px;
    }
  }
`;
export default NotYetBuiltStyled;
