import React from 'react'
import CustomInputStyled from './CustomInputStyled'

function CustomInput(onChange) {
  console.log(typeof onChange);
  return (
    <CustomInputStyled onChange={(e)=>onChange}>
      {

      }
    </CustomInputStyled>
  )
}

export default CustomInput