import React from 'react'
import BottomBarStyled from './BottomBarStyled'

function BottomBar() {
  return (
    <BottomBarStyled>
      <div className="overlay">
        <h2>Support the eco-friendly with Marine</h2>
        <button>Show Instagram</button>
      </div>
    </BottomBarStyled>
  )
}

export default BottomBar