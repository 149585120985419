import styled from "styled-components";
const SearchStyled = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  button {
    border: none;
    background-color: var(--app-black);
    padding: 8px 13px;
    border-radius: 20px;
  }
`
export default SearchStyled;