import React from 'react'
import MarineGreyComponent from '../../Shared/MarineGreyComponent/MarineGreyComponent'
import MarineWhiteComponent from '../../Shared/MarineWhiteComponent/MarineWhiteComponent'
import OtherSectionsStyled from './OtherSectionsStyled'

function OtherSections() {
  return (
    <OtherSectionsStyled>
      <MarineGreyComponent 
        title="해양동물에게 '달콤한 하루'를 선물하세요." 
        image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1635814353392_500.png"
      >
        <p>
          제주 우뭇가사리로 만든 쫄깃한 식감의 프리미엄 디저트입니다. 
        </p>
        <p>
          소셜 벤처기업으로써 첫 제품인 달하루를 구상하였고,
        </p>
        <p>
          패키지에 사회적 가치를 담아내고 싶었습니다. 
        </p>
        <p>
          무분별한 일회용 플라스틱 사용으로 고통받는 해양동물을 살리고 
        </p>
        <p>
          환경을 보존하자는 마음으로 달하루 패키지에 7마리 동물의 일러스트를 넣어 의미를 새겼습니다. 
        </p>
        <p>
          달하루를 구매함으로써, 해양동물을 살리고 환경을 보존하는 일에 
        </p>
        <p>
          동참할 수 있도록 수익금 일부는 환경단체에 기부하고 있습니다. 
        </p>
      </MarineGreyComponent>
      <MarineWhiteComponent title="해양동물을 살리는 달하루의 스토리" image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1580401064726_750.png"/>
      <MarineGreyComponent title="하루 7가지 해초로 매일 간편하게 즐기는 건강함" image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1635814759471_300.png">
        <p>하루에 한번 100% 국내산 7가지 해초(채미역귀, 불등가사리, 한천,</p>
        <p>채다시마, 세모가사리, 꼬시래기, 컷트미역)로 숨가쁘게 살아가는 </p>
        <p>현대인들을 위한 건강한 균형잡힌 식단을 만나보세요.</p>
        <p>미역과 다시마 함량을 줄이고 귀하디 귀한 </p>
        <p>국내산 불등가사리와 세모가사리의 양을 더 늘려 맛과 건강을 두배로 </p>
        <p>챙겨드리는 하루7초 해초샐러드 입니다. </p>
      </MarineGreyComponent>
      <MarineWhiteComponent title="100% 국내산(완도산) 7가지 해초로 구성된 하루7초" image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1635815033871_750.png" />
      <MarineGreyComponent title="자연을 나누어 담다, 자누담" image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1635815204862_500.png">
        <p>자누담은 '자연을 나누어 담다'라는 의미로 해조류 부산물과 </p>
        <p>친환경 소재로 만든 제품군 브랜드입니다. </p>
        <p>자누담은 제품을 제작하여 사용하고 버려질 때까지 사람과 자연이 </p>
        <p>더불어 살아가는 지구를 생각합니다. </p>
        <p>보다 건강하고 다음세대를 위한 자누담의 즐거운 도전, </p>
        <p>자연에서 온 원료를 오롯이 담아낸 자누담 제품을 통해 건강한 그린 라이프를 제안합니다. </p>
      </MarineGreyComponent>
      <MarineWhiteComponent title="자연에서 와서, 다시 자연으로 돌아가다." image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1635815853049_750.jpg" />
    </OtherSectionsStyled>
  )
}

export default OtherSections