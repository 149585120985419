import styled from "styled-components";
const QuestionDetailStyled = styled.div `
  padding: 15px;
  &>a {
    display: block;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    font-size: .9em;
  }
`
export default QuestionDetailStyled;