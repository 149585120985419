import styled from "styled-components";
const CustomerReviewStyled = styled.div `
  display: grid;
  grid-template-columns: 75% auto;
  /* grid-gap: 20px; */
  font-family: var(--app-font-family);
  line-height: 27px;
  border-bottom: 1px solid var(--app-border-color);
  padding: 10px 0px;
  color: var(--app-black);
  font-size: 14px;
  &>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .img {
    height: 50px;
    width: 60%;
    background-image: url(${props => props.backgroundImg});
    margin-bottom: 5px;
  }
  .middle {
    display: flex;    
  }
`

export default CustomerReviewStyled;