import React from 'react'
import { FaLock } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import QuestionStyled from './QuestionStyled'

function Question({question}) {
  return (
    <QuestionStyled>
      <p>
      <Link to={`/questions/${question.id}`}>
        {
          question.protected && <FaLock />
        }
        {question.subject}
      </Link>
      </p>
      <p style={{paddingTop: '10px'}}>
        {question.writer} / Views {question.views} / {question.date}
      </p>
    </QuestionStyled>
  )
}

export default Question