import styled from "styled-components";
const LoginPageStyled = styled.div `
  padding: 15px;
  h2 {
    font-weight: 500;
  }
  label {
    display: block;
    margin: 15px 0px;
    font-size: 14px;
  }
  form {
    font-size: 16px;
  }
  form>div {
    margin: 20px 0px;
  }
  div.other-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    a,span {
      color: var(--app-pink);
      font-weight: 500;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buttons button {
    padding: 17px;
    font-size: 1em;
    margin: 10px 0px ;
  }
`
export default LoginPageStyled;