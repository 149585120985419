import React from 'react'
import { FaRegStar, FaStar } from 'react-icons/fa'
import RatingStyled from './RatingStyled'

function Rating({rating}) {
  return (
    <RatingStyled>
      {
        rating >= 1 ? <FaStar size={14} color='var(--app-black)' /> : <FaRegStar />
      }
      {
        rating >= 2 ? <FaStar size={14} color='var(--app-black)' /> : <FaRegStar />
      }
      {
        rating >= 3 ? <FaStar size={14} color='var(--app-black)' /> : <FaRegStar />
      }
      {
        rating >= 4 ? <FaStar size={14} color='var(--app-black)' /> : <FaRegStar />
      }
      {
        rating >= 5 ? <FaStar size={14} color='var(--app-black)' /> : <FaRegStar />
      }
    </RatingStyled>
  )
}

export default Rating