import styled from "styled-components";
const TermsConditionsStyled = styled.div `

  &>div{
    display: flex;
    align-items: flex-start;
    color: var(--app-black);
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0px;
  }
  span {
    /* padding-left: 10px; */
    display: inline-block;
  }
  .checkbox-wrapper {
    margin-top: 4px;
    margin-right: 5px;
  }
`
export default TermsConditionsStyled;