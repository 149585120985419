import styled from "styled-components";
const ThumbnailSliderStyled = styled.div `
  img {
    width: 100%;
    min-height: 300px;
    margin: 0;
  }
  .images {
    margin: 0;
    padding: 0;
  }
  .images img {
    width: 50px;
    height: 50px;
    min-height: 50px;
    margin-right: 6px;
  }

`

export default ThumbnailSliderStyled;