import styled from "styled-components";

const MarineGreyComponentStyled = styled.div `
  align-items: flex-start;
  background-color: #F2F2F2;
  padding: 15px;
  line-height: 35px;
  /* white-space: pre-wrap; */
  color: var(--app-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--app-font-family);
  h2 {
    font-size: 1.4em;
    margin-bottom: 30px;
  }
  img {
    width: 200px;
  }
`
export default MarineGreyComponentStyled;