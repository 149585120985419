import styled from "styled-components";
const WhiteButtonStyled = styled.button `
    padding: 15px 30px;
    border-radius: 30px;
    width: ${props => props.width ? props.width:'100%'};
    background-color: #fff;
    color: var(--app-black);
    border: 1px solid var(--app-black);
    white-space: nowrap;

`
export default WhiteButtonStyled;