import React from 'react'
import { Link } from 'react-router-dom'
import summarise from '../../../Utils/summarise'
import Rating from '../../Shared/Rating/Rating'
import CustomerReviewStyled from './CustomerReviewStyled'

function CustomerReview({review}) {
  return (
    <CustomerReviewStyled backgroundImg={review.image}>
      <div>
        <Link to={`/reviews/${review.id}`}>
          <p>{summarise(review.subject)}</p>
        </Link>
        <div className="middle">
          <p>{summarise(review.writer, 18)}</p>
          &nbsp; &nbsp;
          <p>/ {review.date}</p>
        </div>
        <p>{summarise(review.product)}</p>
      </div>
      <div>
        <div className="img">
        </div>
        <Rating rating={review.rating} />
      </div>
    </CustomerReviewStyled>
  )
}

export default CustomerReview