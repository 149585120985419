import React from 'react'
import Review from '../../ProductDetail/Review/Review'

function ReviewList({reviews}) {
  return (
    <>
      {
        reviews.map((review, index)=>(
          <Review review={review} key={index} />
        ))
      }
    </>
  )
}

export default ReviewList