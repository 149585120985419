import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import Overview from '../../Components/Shared/Overview/Overview'
import ProductsSection from '../../Components/Shared/ProductsSection/ProductsSection'
import { constants } from '../../constants'
import axios from 'axios';
import CompanyDetailStyled from './CompanyDetailStyled'
import Review from '../../Components/ProductDetail/Review/Review'
import { BiBookmarkHeart, BiHeart } from 'react-icons/bi'

function CompanyDetail(props) {
  const [products, setProducts] = useState([]);

  const location = useLocation();
  const state = location.state;

  let serviceURI = constants.baseURI + constants.productService + "products/company?company_id="+state.id;
  const fetchProducts = async () => {
    setProducts([]);
    const response = await axios.get(serviceURI);
  
    setProducts(response.data); 
  };

  useEffect(() =>{
    fetchProducts();
  },[]);
 
  return (
    <CompanyDetailStyled>
      <Sidebar />
      <Overview homeSlider={false} data={[state.image]} />
      <div className="introduction">
        <div className='header'>
          <h2>{state.name}</h2>
          {/* <BiHeart size={25} /> */}
          {/* <p>{state.like}</p> */}
        </div>
        <p>{state.intro}</p>
      </div>
      {/* <CustomSection title={state.title} image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1657788545814_300.png" /> */}
      <ProductsSection
        title={state.title}
        products={products}
        buttons={[
          {
            title: "All",
            active: true,
          },
          {
            title: "달하루 양갱",
            active: false,
          },
        ]}
      />
    </CompanyDetailStyled>
  );
}

export default CompanyDetail