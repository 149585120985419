import styled from "styled-components";
const SidebarStyled = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: ${(props) => (props.showSidebar ? `0` : `-100%`)};
  transition: left 0.2s ease;

  top: 0;
  z-index: 150;
  .wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 12;
  }
  .sidebar {
    position: absolute;
    width: 83%;
    right: 0;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    padding: 30px 22px;
    z-index: 15;
  }
  .sidebar .upper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .sidebar a {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
  }
  .sidebar .search {
    border: 1px solid var(--app-black);
    padding: 5px 7px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar .search input {
    border: none;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    width: 100%;
    margin-left: 10px;
    margin-right: 3px;
  }
  .sidebar li {
    margin: 33px 0px;
  }

  .sidebar li.nested {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  .sidebar li .nested-list {
    position: relative;
  }
  .sidebar li.active a {
    color: #666666;
  }
  .sidebar li .nested-list li {
    margin-bottom: 0px;
    margin-left: 20px;
  }
  .nav-toggler {
    position: absolute;
    left: 90%;
    top: 50px;
  }
  .nav-toggler span {
    height: 1.5px;
    width: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    position: relative;
  }
  .nav-toggler span::before {
    position: absolute;
    content: "";
    height: 1.5px;
    width: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    top: -5px;
  }
  .nav-toggler span::after {
    position: absolute;
    content: "";
    height: 1.5px;
    width: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 5px;
  }
`;
export default SidebarStyled;
