import styled from "styled-components";
const ShareStyled = styled.div `
  position: absolute;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  padding: 15px 10px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul li a{
    display: flex;
    align-items: center;
    font-family: var(--app-font-family);
    font-size: 14px;
    margin: 10px 0px;
  }
  ul li a span {
    margin-left: 5px;
    margin-right: 20px;
  }
  
`
export default ShareStyled;