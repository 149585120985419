import styled from "styled-components";
const HeaderStyled = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
    padding-bottom: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.7;
    z-index: 100;
    div {
        flex: 1;
    }
    .nav-toggler span {
        height: 1.5px;
        width: 18px;
        background-color: rgba(0,0,0,.5);
        display: inline-block;
        position: relative;
    }
    .nav-toggler span::before {
        position: absolute;
        content: "";
        height: 1.5px;
        width: 18px;
        background-color: rgba(0,0,0,.5);
        top: -5px;
    }
    .nav-toggler span::after {
        position: absolute;
        content: "";
        height: 1.5px;
        width: 18px;
        background-color: rgba(0,0,0,.5);
        top: 5px;
    }
    .icons {
        display: flex;
        justify-content: right;
        align-items: center;
    }
`
export default HeaderStyled;