import styled from "styled-components";
const PostCommentStyled = styled.div `
  label {
    font-family: var(--app-font-family);
    display: block;
    margin: 15px 0px;
    font-weight: 300;
    color: var(--app-black);
  }
  &>div {
    margin-top: 20px;
  }
`
export default PostCommentStyled;