import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CustomSection from '../../Components/Layout/CustomSection/CustomSection'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import Overview from '../../Components/Shared/Overview/Overview'
import ProductsSection from '../../Components/Shared/ProductsSection/ProductsSection'
import { constants } from '../../constants'
import ProductsPageStyled from './ProductsPageStyled'
import axios from 'axios';

function ProductsPage(props) {
  const [products, setProducts] = useState([]);

  const location = useLocation();
  const state = location.state;

  let serviceURI = constants.baseURI + constants.productService + "products?whichstar="+state.menu+"&tag="+state.title;
  const fetchProducts = async () => {
    setProducts([]);

    console.log("start get product!!");

    const response = await axios.get(serviceURI);

    console.log(response.data);
    console.log(response.status);
    console.log(response.headers);
    console.log(response.statusText);
  
    setProducts(response.data); 
  };

  useEffect(() =>{
    fetchProducts();
  },[state.menu, state.title]);
 
  return (
    <ProductsPageStyled>
      <Sidebar />
      <Overview
      homeSlider={false}
      data={[
          "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1638780041331_2500.jpg"
      ]} />
      {/* <CustomSection title={state.title} image="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1657788545814_300.png" /> */}
      <ProductsSection 
        title={state.title}
        products={products}
        buttons={[
          {
            title: "All",
            active: true
          },
          {
            title: "달하루 양갱",
            active: false
          }
        ]}
      />
    </ProductsPageStyled>
  )
}

export default ProductsPage