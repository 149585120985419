import React from 'react'
import TermsConditionsStyled from './TermsConditionsStyled'

function TermsConditions() {
  return (
    <TermsConditionsStyled>
      <div>
        <div className="checkbox-wrapper">
          <input type="checkbox" id="everyone" />
        </div>
        <span>
          모두 동의합니다.
        </span>
      </div>
      <div>
        <div className='checkbox-wrapper'>
          <input type="checkbox" id="terms-of-use" />
        </div>
        <span>
          (필수) <span style={{color: "var(--app-pink)"}}>이용약관과 개인정보 수집 및 이용</span>에 동의합니다.
        </span>
      </div>
      <div>
        <div className="checkbox-wrapper">
          <input type="checkbox" id="mature" />
        </div>
        <span>
        (필수) 만 14세 이상입니다. <br />
        만 19세 미만의 미성년자가 결제 시 법정대리인이 거래를 취소할 수 있습니다.
        </span>
      </div>
      <div>
        <div className="checkbox-wrapper">
          <input type="checkbox" id="receive-email" />
        </div>        
        <span>
        (선택) 이메일 및 SMS 마케팅 정보 수신에 동의합니다.
        회원은 언제든지 회원 정보에서 수신 거부로 변경할 수 있습니다.
        </span>
      </div>
    </TermsConditionsStyled>
  )
}

export default TermsConditions