import styled from "styled-components";
const SlideBtnStyled = styled.div `
  width: 22px;
  height: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 11px;
    height: 11px;
    background-color: ${props=>(props.active ? 'white':'transparent')};
    border: 1.5px solid white;
    border-radius: 50%;
    transition: background-color ease .5s;
  }
`
export default SlideBtnStyled;