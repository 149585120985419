import styled from "styled-components";
const CustomSectionStyled = styled.div `
  text-align: center;
  background-color: ${props => (props.homePage ? '#fff': '#FAF9F6')};
  padding: 20px;
  img {
    width: 75px;
  }
  p {
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
  }
`

export default CustomSectionStyled;