import React from 'react'
import WhiteButtonStyled from './WhiteButtonStyled'

function WhiteButton({title, width, onClick, children}) {
  return (
    <WhiteButtonStyled width={width} onClick={()=>onClick()}>  
      {
        title ? title : children 
      }
    </WhiteButtonStyled>
  )
}

export default WhiteButton