import styled from "styled-components";
const ProductListStyled = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  grid-row-gap: 30px;
  justify-content: center;
`;
export default ProductListStyled;
