import styled from "styled-components";
const SearchComponentStyled = styled.div `
  display: flex;
  justify-content: space-between;
  font-family: var(--app-font-family);
  font-size: 15px;
  padding: 15px;
  input {
    border: none;
    outline: none;
    width: 90%;
    font-size: 1.1em;
  }
`
export default SearchComponentStyled;