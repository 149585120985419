import React from 'react'
import MarineOverviewStyled from './MarineOverviewStyled'

function MarineOverview() {
  return (
    <MarineOverviewStyled>
      <div className='introduction'>
        <h2>Add value to nature</h2>
        <p>
          자연에서 온 참신한 신소재를 개발해, 지구를 보호할 수 있는 친환경적이고 가치 중심적인 제품을 제공합니다.
        </p>
      </div>
      <div className='right-thoughts'>
        <img src="https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1657253724360_1000.jpg" alt="nature" />
        <div>
          <h2>"Right thoughts and actions for the next generation."</h2>
          <p>
            ‘친환경 상품으로 다음 세대를 위해 건강하고 깨끗한 지구를 만들자’라는 비전과 함께 마린이노베이션은 성장하고 있습니다. 해조류, 식물성 원료, 커피 등 다양한 원료를 연구 개발하여 최고의 친환경 신소재를 개발하겠습니다. 친환경 신소재를 통해 친환경 글로벌 기업으로 발전할 수 있는 마린이노베이션이 되겠습니다.
          </p>
        </div>
      </div>
    </MarineOverviewStyled>
  )
}

export default MarineOverview