import styled from "styled-components";
const MarineOverviewStyled = styled.div `
  margin-bottom: 120px;
  .introduction {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 200px;
  }
  h2 {
    font-family: "Nanum Gothic", sans-serif;
    color: var(--app-black);
  }
  p {
    color: var(--app-black);
    line-height: 35px;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 15px;
  }
  img {
    width: 100%;
    margin: 50px 0px 100px 0px;
  }
  .right-thoughts h2 {
    line-height: 1.4em;
    margin: 50px 0px;
    font-size: 23px;
  }
`
export default MarineOverviewStyled;