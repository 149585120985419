import styled from "styled-components";
const CartItemStyled = styled.div `
  .img {
    background-image: url(${props=>props.backgroundImg});
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
  }
  font-weight: 300;
  font-size: 15px;
  &>div {
    display: flex;
    grid-gap: 15px;
    align-items: flex-start;
    padding: 20px 0px;
    border-top: 1px solid var(--app-border-color);
  }
  &>div:nth-child(2) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  div.item-details {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;
  }

`
export default CartItemStyled;