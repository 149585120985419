import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import Overview from "../../Components/Shared/Overview/Overview";
import CompanyPageStyled from "./CompanyPageStyled";

function CompanyPage() {
  const [companys, setCompanys] = useState([]);

  const fetchCompanys = async () => {
	setCompanys();
	setCompanys([
		{id : 1, name : "누야하우스", image:"https://mykcbucket.s3.ap-northeast-2.amazonaws.com/kc/kc_msa_work/nuyahouse.jpg", like:"100",  intro : "누야 하우스는 사람을 위한 친환경제품 생산을 모토로 취약계층의 고용 창출을 위해 노력하고 있습니다. 대량생산을 통한 원가 절감으로 친환경제품을 대중에 공급할 수 있도록 노력하고 있으며, ISO9001 품질경영시스템 인증 및 ISO14001 환경경영시스템 인증을 통해 제품을 생산하고 친환경제품을 활용한 화장품 및 비누를 생산하는 중증장애인 생산품 판매시설이며 사회적 기업입니다."},
		{id : 2, name : "리벨롭", image:"https://mykcbucket.s3.ap-northeast-2.amazonaws.com/kc/kc_msa_work/revelop.jpg", like:"101",  intro : "(주)리벨롭은 환경생태적 윤리를 기본으로 그린디자인 제품을 개발 및 생산하는 디자인회사입니다. 친환경제품에 대한 인지도 상승효과로 소비자 및 고객에게 다가가 다음세대가 살아갈 지구환경에 작은 보탬이 되는 것이 목표입니다."},
		{id : 3, name : "그레이프랩", image:"https://mykcbucket.s3.ap-northeast-2.amazonaws.com/kc/kc_msa_work/graperap.jpg", like:"102",  intro : "그레이프랩은 환경과 사회문제를 디자이너의 관점에서 바라보고 다양한 실험을 통해 그 해결책을 제시하는 디자인 스튜디오입니다. 버려지는 자원과 우리 사회의 소외된 계층과 관련한 문제를 해결하기 위해 최소한의 자원과 적정 기술을 활용해 지속가능한 디자인 제품을 개발하고 있습니다."},
		{id : 4, name : "네이처수", image:"https://mykcbucket.s3.ap-northeast-2.amazonaws.com/kc/kc_msa_work/nature_su.jpg", like:"103",  intro : "네이처 수는 사람의 겉 피부 중 가장 예민하고 여린 피부에 적합한 친환경 브랜드입니다.그만큼 많은 고민과 샘플링, 시행착오를 겪어야만 했고 완벽에 완벽을 부단히 노력하고 있습니다."},
		
	]);
  };

  useEffect(() => {
    fetchCompanys();
  }, []);

  return (
    <CompanyPageStyled>
      <Sidebar />
      {companys.map((company, index) => {
        return (
          <div className="company">
            <div className="introduction">
              <h2>{company.name}</h2>
              <p>{company.intro}</p>
            </div>
            <Link to={`/companys/detail/${company.id}`} state={companys[index]}>
              <Overview
              homeSlider={false}
              data={[company.image]}
              /> 
            </Link>
          </div>
        );
      })}
    </CompanyPageStyled>
  );
}

export default CompanyPage;
