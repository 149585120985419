import React from 'react'
import BlackButtonStyled from './BlackButtonStyled'

function BlackButton({title, width, onClick, children, type}) {
  return (
    <BlackButtonStyled width={width} onClick={()=>onClick()} type={type}>  
      {
        title ? title : children 
      }
    </BlackButtonStyled>
  )
}

export default BlackButton