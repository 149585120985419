import styled from "styled-components";
const CompanyDetailStyled = styled.div `
.company{
  margin : 32px 0px 32px 0px;
}
.introduction {
  padding : 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.header{
  display: flex;
  flex-direction: row;
}
h2 {
  font-family: "Nanum Gothic", sans-serif;
  color: var(--app-black);
  margin: 0px 8px 12px 0px;
}
p {
  color: var(--app-black);
  line-height: 35px;
  font-family: "Nanum Gothic", sans-serif;
  font-size: 15px;
}
`

export default CompanyDetailStyled;