import styled from "styled-components";
const EventPageStyled = styled.div `
  padding: 0px 15px 15px 15px;
  h2 {
    font-family: var(--app-font-family);
    text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 500;
  }
`
export default EventPageStyled;