import styled from "styled-components";
const PostReviewFormStyled = styled.div `
  p.title {
    font-family: var(--app-font-family);
    font-weight: 500;
    font-size: 20px;
    margin: 10px 0px;
  }
  div.login-div {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    background-color: #fafafa;
    border: 1px solid #d4d4d4;
  }
  //style the select
  select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-color: #fafafa;
    height: 45px;
    width: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
    background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 0;
    background-size: 20px;
    outline: none;
    color: var(--app-semi-black);
  
  }

  //stye the file label
  label {
   cursor: pointer;
   border-radius: 50%;
   background-color: #a4a4a4;
   width: 50px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 5px;
  }
  .form div:nth-child(3) {
    display: grid;
    grid-template-columns: 50% auto;
    margin-top: 10px;
    grid-gap: 10px;
  }

  #image {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

`
export default PostReviewFormStyled;