import React, { useContext, useState, useEffect } from "react";
import { BiSearch, BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SidebarStyled from "./SidebarStyled";
import Logo from "../../../Assets/logo.png";
import SidebarContext from "../../../Context/SidebarContext";

function Sidebar({ active }) {
  const [aboutUp, setAboutUp] = useState(false);
  const [valueUp, setValueUp] = useState(false);
  const [reviewsUp, setReviewsUp] = useState(false);
  const { state: sidebarState, dispatch: sidebarDispatch } =
    useContext(SidebarContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // sessionStorage에서 로그인 상태 확인
    const isLogined = sessionStorage.getItem("isLogined");
    setIsLoggedIn(isLogined === "true");
  }, []);

  const handleLoginLogout = () => {
    if (isLoggedIn) {
      // 로그아웃 처리
      sessionStorage.removeItem("isLogined");
      setIsLoggedIn(false);
      // history.push("/");
      navigate("/");
    } else {
      // 로그인 페이지로 이동
      // history.push("/login");
      navigate("/login");
    }
    onNavigate();
  };

  const onNavigate = () => {
    sidebarDispatch({ type: "HIDE_SIDEBAR" });
  };

  return (
    <SidebarStyled showSidebar={sidebarState.showSidebar}>
      <div
        className="wrapper"
        onClick={() => sidebarDispatch({ type: "HIDE_SIDEBAR" })}
      >
        <div className="sidebar">
          <div
            style={{ width: "100%", height: "100%" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="upper">
              <img style={{ width: "100px" }} src={Logo} alt="Logo" />
              <Link to="/login" onClick={handleLoginLogout}>
              {isLoggedIn ? "로그아웃" : "로그인"}
              </Link>
            </div>
            <div className="search">
              <BiSearch opacity={0.5} size={27} />
              <input type="text" />
            </div>
            <div className="menu">
              <ul>
                <li className={active === "home" ? "active" : ""}>
                  <Link to="/" onClick={onNavigate}>
                    HOME
                  </Link>
                </li>
                <li
                  className={active === "about" ? "active nested" : "nested"}
                  onClick={() => setAboutUp(!aboutUp)}
                >
                  <div>
                    <Link to="#">PRODUCT</Link>
                    {aboutUp && (
                      <ul className="nested-list">
                        <li>
                          <Link
                            to="/products/productstar/화장품"
                            state={{
                              menu: "productstar",
                              title: "화장품",
                              index: 1,
                            }}
                            onClick={onNavigate}
                          >
                            화장품
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/products/productstar/생활용품"
                            state={{
                              menu: "productstar",
                              title: "생활용품",
                              index: 2,
                            }}
                            onClick={onNavigate}
                          >
                            생활용품
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/products/productstar/주방용품"
                            state={{
                              menu: "productstar",
                              title: "주방용품",
                              index: 3,
                            }}
                            onClick={onNavigate}
                          >
                            주방용품
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                  {aboutUp ? (
                    <BiChevronUp size={20} />
                  ) : (
                    <BiChevronDown size={20} />
                  )}
                </li>
                <li
                  className={active === "value" ? "active nested" : "nested"}
                  onClick={() => setValueUp(!valueUp)}
                >
                  <div>
                    <Link to="#">VALUE</Link>
                    {valueUp && (
                      <ul className="nested-list">
                        <li>
                          <Link
                            to="/products"
                            state={{
                              menu: "valuestar",
                              title: "장애인채용",
                              index: 1,
                            }}
                            onClick={onNavigate}
                          >
                            장애인 채용
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/products"
                            state={{
                              menu: "valuestar",
                              title: "친환경",
                              index: 2,
                            }}
                            onClick={onNavigate}
                          >
                            친환경
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                  {valueUp ? (
                    <BiChevronUp size={20} />
                  ) : (
                    <BiChevronDown size={20} />
                  )}
                </li>
                <li className={active === "company" ? "active" : ""}>
                  <Link to="/companys" onClick={onNavigate}>
                    COMPANY
                  </Link>
                </li>
                <li className={active === "event" ? "active" : ""}>
                  <Link to="/event" onClick={onNavigate}>
                    GOODNEWS
                  </Link>
                </li>
                {/* <li
                  className={active === "reviews" ? "active nested" : "nested"}
                  onClick={() => setReviewsUp(!reviewsUp)}
                >
                  <div>
                    <Link to="#">REVIEWS</Link>
                    {reviewsUp && (
                      <ul className="nested-list">
                        <li>
                          <Link to="/questions" onClick={onNavigate}>
                            Q&A
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                  {reviewsUp ? (
                    <BiChevronUp size={20} />
                  ) : (
                    <BiChevronDown size={20} />
                  )}
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="nav-toggler">
        <span></span>
      </div>
    </SidebarStyled>
  );
}

export default Sidebar;
