import styled from "styled-components";
const FilterButtonsStyled = styled.div `
  display: flex;
  overflow-x: auto;
  padding: 20px 0px;
  &::-webkit-scrollbar {
    width: 0;
  }
  button {
    padding: 2px 15px;
    font-size: 13px;
    font-family: Rubik sans-serif;
    margin: 3px 7.5px 3px 0px;
    border-radius: 50px;
    border: 1.5px solid var(--app-blue);
    background-color: #fff;
    min-height: 30px;
    align-items: center;
    display: block;
    width: fit-content;
    white-space: nowrap;
  }
  button.active {
    background-color: var(--app-blue);
    color: #fff;
  }
`
export default FilterButtonsStyled;