import React from 'react'
import EventCardStyled from './EventCardStyled'

function EventCard({event}) {
  return (
    <EventCardStyled>
      <img src={event.image} alt={event.title} />
      <div className='overlay'>
        <div className='inner'>
          <p>{event.title}</p>
          <p>{event.date}</p>
          <p>{event.time}</p>
        </div>
      </div>
    </EventCardStyled>
  )
}

export default EventCard