import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import CartTableStyled from "./CartTableStyled";

function CartTable({
  increaseQuantity,
  reduceQuantity,
  onChangeQuantity,
  quantity,
}) {
  return (
    <CartTableStyled>
      <tbody>
        <tr>
          {/* <td>
            <button onClick={reduceQuantity} >
              <FaMinus color="#d6d6d6" />
            </button>
          </td> */}
          <td>
            <input type="text" value={quantity} onInput={onChangeQuantity} />
          </td>
          {/* <td>
            <button onClick={increaseQuantity}>
              <FaPlus color='#d6d6d6'/>
            </button>
          </td> */}
        </tr>
      </tbody>
    </CartTableStyled>
  );
}

export default CartTable;
