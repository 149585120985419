import React from "react";
import UnderConstructionSVG from "../../Assets/under_construction.svg";
import NotYetBuiltStyled from "./NotYetBuiltStyled";
import Sidebar from "../../Components/Layout/Sidebar/Sidebar";
import { BsExclamationCircle } from "react-icons/bs";

export default function NotYetBuilt() {
  return (
    <NotYetBuiltStyled>
      <Sidebar />
      <div className="content">
        <BsExclamationCircle size={"300px"} color="#044CA5" />
        <h3>This page is being prepared.</h3>
        <p>
          We are currently preparing the page, so please be patient. Thank you.
        </p>
        <button
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
    </NotYetBuiltStyled>
  );
}
