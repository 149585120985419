import React from 'react'
import FilterButtonsStyled from './FilterButtonsStyled'

//we pass an array of button titles as prop
function FilterButtons({buttons, onClick}) {
  const clicked = (index) => {
    onClick(index);
  } 
  return (
    <FilterButtonsStyled>
      {
        buttons.map((button, index)=>{
          return (
            <button 
              className={button.active ? 'active': ''} 
              key={index}
              onClick={()=>clicked(index)}
              >{button.title}</button>
          )
        })
      }
    </FilterButtonsStyled>
  )
}

export default FilterButtons