import React from 'react'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import BlackButton from '../../Components/Shared/BlackButton/BlackButton'
import CustomInput from '../../Components/Shared/CustomInput/CustomInput'
import WhiteButton from '../../Components/Shared/WhiteButton/WhiteButton'
import TermsConditions from '../../Components/SignupPage/TermsConditions/TermsConditions'
import SignupPageStyled from './SignupPageStyled'

function SignupPage() {
  return (
    <SignupPageStyled>
      <Sidebar />
      <div>
        <h2>회원 가입</h2>
        <form>
          <div className="email">
            <label htmlFor="email">이메일 *</label>
            <CustomInput />
          </div>
          <div className="name">
            <label htmlFor="name">이름 *</label>
            <div>
              <CustomInput />
              <WhiteButton title="본인 인증" width="fit-content" />
            </div>
          </div>
          <div className="password">
            <label htmlFor="password">비밀번호 *</label>
            <CustomInput />
          </div>
          <div className="confirm-password">
            <label htmlFor="confirm-password">비밀번호 확인 *</label>
            <CustomInput />
          </div>
          <div className="mobile-phone-number">
            <label htmlFor="mobile-phone-number">
              휴대폰 번호 *
            </label>
            <div>
              <CustomInput />
              -
              <CustomInput />
              -
              <CustomInput />
            </div>
          </div>
          <div className="zip-code">
            <label htmlFor="zip-code">우편번호 *</label>
            <div>
              <CustomInput />
              <WhiteButton width="fit-content" title="검색하기" />
            </div>
          </div>
          <div className="address">
            <label htmlFor="address">주소 *</label>
            <div>
              <CustomInput />
              <CustomInput />
            </div>            
          </div>
          <TermsConditions />
          <div className="submit">
            <BlackButton title="가입하기" width="50%" />
          </div>
          <div className="socials">
            {/*We will add social signup buttons here. 
              they are provided with these social medias
              by default
            */}
          </div>
        </form>
      </div>
    </SignupPageStyled>
  )
}

export default SignupPage