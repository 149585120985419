import styled from "styled-components";
const PaginatorStyled = styled.div `
  display: flex;
  justify-content: center;
  margin-top: 40px;
  button {
    background-color: #fff;
    margin: 0px 10px;
    cursor: pointer;
    border: none;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
`
export default PaginatorStyled;