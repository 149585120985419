import { createContext, useReducer } from "react";

const sidebarReducer = (initialState, action) => {
  switch (action.type){
    case 'SHOW_SIDEBAR':
      return {...initialState, showSidebar: true}
    case 'HIDE_SIDEBAR':
      return {...initialState, showSidebar: false}

    default:
      return {...initialState}
  }
}

const SidebarContext = createContext();

export const SidebarProvider = ({children}) => {
  const [state, dispatch] = useReducer(sidebarReducer, {
    showSidebar: false
  })

  return <SidebarContext.Provider value={{state: state, dispatch: dispatch}}>
    {children}
  </SidebarContext.Provider>
}

export default SidebarContext;