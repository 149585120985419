import React from 'react'
import Sidebar from '../../Components/Layout/Sidebar/Sidebar'
import EventCard from '../../Components/Shared/EventCard/EventCard'
import EventPageStyled from './EventPageStyled'

function EventPage() {
  const events = [
    {
      id: 1,
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1657844738579_750.jpg"
    },
    {
      id: 2,
      title: "다같이줍깅",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1654826461903_750.png",
      date: "22.06.13",
      time: "- 06.15"
    },
    {
      id: 3,
      title: "DIN CERTCO",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1654647282067_750.jpg",
      date: "이벤트 종료"
    },
    {
      id: 4,
      title: "바다를 지켜줘",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1646288182524_750.jpg",
      date: "22.03.01",
      time: "-03.31"
    },
    {
      id: 5,
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1657844738579_750.jpg"
    },
    {
      id: 6,
      title: "다같이줍깅",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1654826461903_750.png",
      date: "22.06.13",
      time: "- 06.15"
    },
    {
      id: 7,
      title: "DIN CERTCO",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1654647282067_750.jpg",
      date: "이벤트 종료"
    },
    {
      id: 8,
      title: "바다를 지켜줘",
      image: "https://contents.sixshop.com/thumbnails/uploadedFiles/116837/default/image_1646288182524_750.jpg",
      date: "22.03.01",
      time: "-03.31"
    }

  ]
  return (

    <EventPageStyled>
      <Sidebar active="event" />
      <h2>EVENT</h2>
      {
        events.map(event=>(
          <EventCard
            event={event}
          />
        ))
      }
    </EventPageStyled>
  )
}

export default EventPage