import React from 'react'
import CustomerReview from '../CustomerReview/CustomerReview'
import ReviewsListStyled from './ReviewsListStyled'

function ReviewsList({reviews}) {
  return (
    <ReviewsListStyled>
      {
        reviews.map((review, index) => (
          <CustomerReview review={review} key={index} />
        ))
      }
    </ReviewsListStyled>
  )
}

export default ReviewsList